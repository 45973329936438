import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b1cd903"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "componentTitle" }
const _hoisted_2 = { class: "card-text mg-top" }
const _hoisted_3 = { class: "card-text" }
const _hoisted_4 = { class: "card-text" }
const _hoisted_5 = { class: "card-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.emailVerifiedTitle), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.emailVerifiedMessage), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.youCanEmailVerified), 1),
    _createElementVNode("p", _hoisted_4, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.closeTab), 1)
  ]))
}