
import { Resources } from '@/core/models/metadata/resources';
import { Services } from '@/core/models/metadata/services';
import { inject } from 'vue';
import { defineComponent } from 'vue';


export default defineComponent({
    data(){
        const resources = inject(Services.Resources) as Resources;
        return {
            emailVerifiedTitle: resources.titles.emailVerified,
            emailVerifiedMessage: resources.messages.emailVerifiedClose,
            closeTab:resources.messages.closeTab,
            youCanEmailVerified:resources.messages.youCanEmailVerified,
            items: [ resources.messages.firstListEmailVerified, resources.messages.secondListEmailVerified, resources.messages.thirdListEmailVerified ]
        };
    },
    methods: {
    }
})
